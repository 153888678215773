import React, { lazy, Suspense } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navigation from './components/navigation';

const About = lazy(() => import("./About"));
const Home = lazy(() => import("./Home"));
const Blogs = lazy(() => import("./Blogs"));

const App: React.FC = () => (
  <>
    <Navigation />
    <Router>
        <Suspense fallback={<div>Loading...</div>}>
            <Routes>
                <Route path="/about"  element={<About />}/>
                <Route path="/" element={<Home />}/>
                <Route path="/blogs/*" element={<Blogs />}/>
            </Routes>
        </Suspense>
    </Router>
  </>
);

export default App;