import styles from '../styles/Navigation.module.css';
export default function Navigation() {

    return (
        <div className={styles.root}>
            <div>
                <figure className={styles.logo}>
                    <a href="/">
                    <img src={"/logo.svg"} alt="DhruTara logo" className={styles.logoImage} />
                    </a>
                    <figcaption className={styles.logoCaption}>DhruTara</figcaption>
                </figure>
            </div>
            <div style={{"width":"100%", "direction":"rtl"}}>
            <ul className={styles.menu}>
                        <li>
                            <a href="/about">About</a>
                        </li>
                        </ul>
            </div>
        </div>
    );
}